@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--navbar-height: 78px;
	--accent-color: #337ccf;
	--background-color: #f2f2f2;
	--interview-code-editor-height: calc(100vh - 112px);
}

* {
	margin: 0;
	padding: 0;
}
*,
*::after,
*::before {
	box-sizing: border-box;
}

img,
svg,
video,
picture {
	display: block;
	max-width: 100%;
}

input,
textarea,
option,
select,
label {
	font-family: inherit;
}

html {
	scroll-behavior: smooth;
}

body {
	line-height: 1.5;
	/* min-height: 100vh; */
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
		"Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.pulsate {
	animation: pulsate 2s infinite;
}

@keyframes pulsate {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.2);
	}

	100% {
		transform: scale(1);
	}
}

.code-editor-bgm {
	background-color: #337ccf;
	padding: 0.625rem;
	color: var(--background-color);
}

.form-box {
	background-color: rgb(51, 124, 207, 0.4);
	/* color: rgb(157, 157, 224); */
}

/* .create-interview-heading::after{
  content: "";
  display: block;
  width: 100px;
  background-color: #337CCF;
  height: 1.3px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-block: 0.625rem;
} */

.boom {
	flex: 1;
}

.hover-over:hover {
	background-color: aliceblue !important;
}

.error-groups {
	animation: shake 0.5s;
	animation-iteration-count: 1;
}

@keyframes shake {
	0% {
		transform: translate(1px, 1px) rotate(0deg);
	}
	10% {
		transform: translate(-1px, -2px) rotate(-1deg);
	}
	20% {
		transform: translate(-3px, 0px) rotate(1deg);
	}
	30% {
		transform: translate(3px, 2px) rotate(0deg);
	}
	40% {
		transform: translate(1px, -1px) rotate(1deg);
	}
	50% {
		transform: translate(-1px, 2px) rotate(-1deg);
	}
	60% {
		transform: translate(-3px, 1px) rotate(0deg);
	}
	70% {
		transform: translate(3px, 1px) rotate(-1deg);
	}
	80% {
		transform: translate(-1px, -1px) rotate(1deg);
	}
	90% {
		transform: translate(1px, 2px) rotate(0deg);
	}
	100% {
		transform: translate(1px, -2px) rotate(-1deg);
	}
}

button:focus {
	border: none !important;
}

.opening-type-container:hover .opening-type-title {
	transform: scale(1.3);
}

.opening-type-container {
	cursor: pointer;
	background-color: #08aeea;
	background-image: linear-gradient(0deg, #08aeea 0%, #2af598 100%);
}
.opening-type-container:nth-of-type(2) {
	background-color: #0093e9;
	background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
}

.opening-type-container .opening-type-title {
	transition: transform 200ms ease-in-out;
	color: white;
}

#opening-name-search,
#candidate-name-search {
	padding: 10px;
	width: 250px;
	border-radius: 1.45rem;
	border: none;
	outline: none;
	box-shadow: 0px 0px 6px #d1d1d1;
}

#opening-name-search {
	width: 220px;
}
:is(#opening-name-search, #candidate-name-search):is(:hover, :active, :focus) {
	outline: #337ccf 2px solid;
}

:is(#opening-name-search, #candidate-name-search)::placeholder {
	color: #b1b1b1;
}

.message-resume-mode:hover {
	outline: 2px solid #585887;
	outline-offset: -2px;
}

.resume-from-selected-message {
	outline: 2px solid #585887;
	outline-offset: -2px;
}

.rating-comp-fix svg {
	max-width: fit-content;
}

::-webkit-scrollbar {
	width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: 0 0 2px 2px #e1e1e1;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #c2c2c2;
	border-radius: 10px;
	cursor: auto;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background-color: #b2b2b2;
}

.outer {
	box-sizing: border-box;
	border: 1px solid black;
	border-radius: 10px;
	padding: 2rem;
	position: relative;
}

.button-inner-child {
	position: absolute;
	box-sizing: border-box;
	background-color: aquamarine;
	inset: 0;
	width: 100%;
	border-radius: 10px;
	height: 100%;
}

button.create-btn-from-jd:hover p,
button.create-btn-from-jd:focus-visible p {
	scale: 1.04;
}

.margin-top-for-nav {
	padding-top: var(--navbar-height, 88px);
}

.scroll-to-top-btn:hover .scroll-to-top-icon {
	color: black;
}

.ai-response-renderer li {
	list-style-position: inside;
}

.skill-child {
	color: #b2b2b2;
}

.md-table table {
	table-layout: fixed;
	border-collapse: collapse;
	width: 100%;
	margin-block: 1rem;
	font-weight: 400;
	font-size: 1rem;
}

.md-table table :is(td, th) {
	border: 1px solid #e5e7eb;
	padding: 8px;
}

.md-table table tr:nth-child(even) {
	background-color: #f3f4f6;
}

.md-table table th {
	padding-block: 8px;
	text-align: left;
	background-color: #3b82f6;
	color: white;
	text-transform: capitalize;
	text-align: center;
}

button {
	font-family: inherit;
}

.codex-editor__redactor {
	padding-bottom: 230px !important;
	text-align: left;
}

.ce-block__content {
	padding: 0 !important;
	margin: 0 !important;
	max-width: 100% !important;
	margin-right: 50px !important;
}

.ce-toolbar__content {
	width: 0px;
	margin: 0 !important;
}

.ce-toolbar__actions {
	left: -3.5rem;
}

.edit-icon-for-rows {
	opacity: 0;
}

.hoverRow:hover .edit-icon-for-rows {
	opacity: 1;
}

.visibility-icon-for-rows {
	opacity: 0;
}

.hoverRow:hover .visibility-icon-for-rows {
	opacity: 1;
}

.ag-row-hover .edit-icon-for-rows {
	opacity: 1;
}

.ag-row-hover .visibility-icon-for-rows {
	opacity: 1;
}

.ag-cell-focus:focus-within {
	border: none !important;
}

.cancel-schedule-icon {
	opacity: 0;
}

.interview-status:hover .schedule-chip {
	padding-right: 1.1rem;
}

.interview-status:hover .cancel-schedule-icon {
	opacity: 1;
	transform: translateX(45%);
	transition: transform 0.5s;
}

.hoverRow:hover .schedule-chip {
	padding-right: 1.1rem;
}

.hoverRow:hover .cancel-schedule-icon {
	opacity: 1;
	transform: translateX(45%);
	transition: transform 0.5s;
}

.ag-watermark {
	display: none !important;
}

.MuiDataGrid-columnHeaderTitleContainerContent {
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	background-color: #F3F4F6;
}

.MuiDataGrid-columnHeaderTitleContainerContent {
	display: flex;
	align-items: center;
	justify-content: center;
}

.MuiDataGrid-cell:focus-within {
	outline: none !important;
}

.border-left::before {
	content: "";
	position: absolute;
	left: 0;
	top: 30%;
	height: 40%;
	width: 1px;
	background-color: #cecdcd;
}
