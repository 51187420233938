#container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#buttons {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.btn {
	margin-top: 0.5rem;
	/* display: none; */
	border: none;
	color: black;
	padding: 0.5rem;
	text-align: center;
	text-decoration: none;
	border-radius: 0.4rem;
	font-size: 12px;
	cursor: pointer;
	/* add fade animation to clicked button */
	&:focus {
		border: 1px solid black;
	}
}

#audio {
	display: none;
}

#video-list {
	width: 100%;
	/* margin-top: 1rem; */
	/* margin-bottom: 0.5rem; */
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

#video-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

video {
	/* border: 1px solid black; */
	/* padding: 2px; */
	width: 100px;
	height: 60px;
}

.video-element-second-device {
	width: 100%;
	height: auto;
}

#admin-video-list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;
	width: 100%;
}

.admin-video-container {
	box-sizing: border-box;
	position: relative;
	margin: 0;
	padding: 0;
	display: inline-block;
}

.fullscreen-button {
	position: absolute;
	bottom: 4px;
	right: 5px;
	background-color: rgba(255, 255, 255, 0.7);
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	cursor: pointer;
	opacity: 0.7;
	z-index: 10;
	transition: opacity 0.3s ease;
}

.fullscreen-button > i {
	font-size: 15px;
}

.fullscreen-button:hover {
	opacity: 1;
}

.fullscreen-icon {
	color: white;
	padding: 5px;
	position: absolute;
	bottom: 5px;
	right: 5px;
	cursor: pointer;
	z-index: 10;
	transition: color 0.3s ease;
}

.fullscreen-icon:hover {
	color: #ddd;
}
