.react-datepicker__tab-loop {
	margin: 0 !important;
}

.react-datepicker__tab-loop {
	margin: 0 !important;
}

/* flatpickr-custom.css */

/* Override calendar container styles */
.flatpickr-calendar {
	background-color: black !important; /* Change background color */
	border: 1px solid #ccc; /* Change border color */
	border-radius: 8px; /* Change border radius */
}
.flatpickr-rContainer{
	width: 100%;
}
.dayContainer{
	width: 100% !important;
	max-width: 100% !important;
}
.flatpickr-days {
	width: 100% !important;
}
.flatpickr-day{ 
	width:15% !important;
	max-width: 15% !important;
}
.flatpickr-weekdays {
	width: 100% !important;
}
/* Override selected date styles */
.flatpickr-day.selected {
	background-color: #007bff; /* Change selected date background color */
	color: #fff; /* Change selected date text color */
}

/* Override today date styles */
.flatpickr-day.today {
	border-color: #007bff; /* Change today's date border color */
}

/* Override other styles as needed */