@media (max-width: 768px) {
    /* Adjust navigation bar for mobile */
    .nav-bar {
        flex-direction: column;
        text-align: center;
    }

    .nav-bar ul {
        margin-top: 1rem;
    }

    /* Adjust hero section padding for mobile */
    .hero-section {
        padding: 4rem 1rem;
    }

    .hero-button {
        margin-top: 1rem;
    }

    /* Adjust image size for mobile */
    .hero-image {
        max-width: 100%;
        height: auto;
    }

    /* Adjust benefit and feature panels for mobile */
    .benefit-panel,
    .feature-panel {
        padding: 1rem;
    }
}
