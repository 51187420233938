/* scroll bar styles */
/* ::-webkit-scrollbar {
    width: 8px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: #d3d3df;
}

::-webkit-scrollbar-thumb {
    background: var(--accent-color);
    border-radius: 10px;
} */

main.admin-panel-container {
	/* background-color: var(--background-color); */
	min-height: 100vh;
	line-height: 1.5;
}
.margin-top-for-nav {
	padding-top: 16px;
}
