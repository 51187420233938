@keyframes flash {
	0%,
	100% {
		border-color: transparent;
		color: transparent;
	}
	50% {
		border-color: darkgray;
		color: black; /* Adjust label color during flash */
	}
}
.flashing-effect {
	animation: flash 2s infinite;
}
