header.interview-header {
	background-color: #f1f1f1;
	padding: 1rem 0;
	box-shadow: 0px 3px 5px rgb(17, 17, 17, 0.45);
	position: relative;
	z-index: 12;
	color: #1f1f1f;
}

header.interview-header nav {
	width: min(100% - 1rem, 1200px);
	margin-inline: auto;
}

@media (max-width: 720px) {
	.interview-main-container {
		display: flex;
		flex-direction: column;
	}
}
