/* targe all the ol ul in #preview-modal and give them a left margin of 5rem */

#preview-modal ol, #preview-modal ul {
  margin-left: 3rem;
  margin-top: .5rem;
    margin-bottom: .5rem;
}

#preview-modal h1, #preview-modal h2, #preview-modal h3, #preview-modal h4, #preview-modal h5, #preview-modal h6, #preview-modal p {
  margin-top: .5rem;
    margin-bottom: .5rem;
}