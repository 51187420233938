header.navbar-header {
	font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva,
		Verdana, sans-serif;
	background-color: #121212;
	color: white;
	padding: 1rem 0;
	box-shadow: 0px 5px 5px rgb(17, 17, 17, 0.45);
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 10;
}

.navbar-header nav {
	width: min(100% - 2rem, 1200px);
	margin-inline: auto;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 1rem;
}

.navbar-header h1.header-logo {
	margin-right: auto;
}

h1.header-logo::after {
	content: "";
	display: block;
	width: 0px;
	height: 4px;
	background-color: var(--accent-color);
	transition: width 0.4s ease-out;
}

h1.header-logo:hover::after {
	width: 100%;
}

.navbar-header h1 a {
	color: white;
	text-decoration: none;
}

.logout-label {
	font-size: 1rem;
}

.logout-button {
	display: flex;
	gap: 0.625rem;
}
