.MuiDialog-paperWidthLg {
	max-width: 100%;
	transition: max-width 300ms ease-in;
}

@media screen and (min-width: 1024px) {
	.MuiDialog-paperWidthLg {
		max-width: 50%;
		transition: max-width 300ms ease-in;
	}
}

.chip {
	display: inline-flex;
	align-items: center;
	padding: 0.5rem 1rem;
	border: 1px solid transparent;
	border-radius: 9999px;
	cursor: pointer;
	transition:
		background-color 0.3s,
		border-color 0.3s;
	margin-right: 0.5rem;
	/* Adds spacing between chips */
	margin-bottom: 0.5rem;
	/* Adds vertical spacing for wrapped chips */
}

.chip.active {
	background-color: #8b5cf6;
	/* Blue 500 */
	color: white;
	border-color: #5b21b6;
}

.chip:not(.active) {
	background-color: #f9fafb;
	/* Gray 100 */
	color: #4b5563;
	/* Gray 600 */
	border-color: #d1d5db;
	/* Gray 300 */
}

.email-chip {
	display: flex;
	align-items: center;
	padding: 0.25rem 0.5rem;
	margin: 0.25rem;
	background-color: #e2e8f0;
	/* Tailwind's gray-200 */
	border-radius: 9999px;
	/* Full rounded */
}

.email-chip span {
	margin-right: 0.5rem;
}

.email-chip .remove-email {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 18px;
	height: 18px;
	border-radius: 9999px;
	background-color: #cbd5e1;
	border: none;
	outline: none;
	/* Tailwind's gray-300 */
}

.info-icon {
	cursor: pointer;
	/* Indicates the icon is interactive */
	display: inline-block;
	vertical-align: middle;
	/* Aligns icon with the middle of the text */
}

.create-update-opening input,
.create-update-opening textarea,
.create-update-opening select,
.create-update-opening #email-chips-container:not(:empty) {
	border: 1px solid rgb(229, 231, 235);
}
.create-update-opening .custom-question-text-field textarea {
	border: none;
}
.opening-create-from-jd-dialog :is(input, textarea, select) {
	border: 1px solid rgb(229, 231, 235);
}

.opening-dialog-content ul,
.opening-dialog-content ol {
	padding-left: 1rem;
}

.opening-dialog-content hr {
	margin-block: 1rem;
}

.opening-create-from-jd-dialog
	:is(
		input:is(:focus, :focus-visible, :focus-within),
		textarea:is(:focus, :focus-visible, :focus-within),
		option:is(:focus, :focus-visible, :focus-within),
		select:is(:focus, :focus-visible, :focus-within)
	) {
	outline-color: rgb(59 130 246);
}

.create-update-opening
	:is(
		input:is(:focus, :focus-visible, :focus-within),
		textarea:is(:focus, :focus-visible, :focus-within),
		option:is(:focus, :focus-visible, :focus-within),
		select:is(:focus, :focus-visible, :focus-within)
	) {
	outline-color: rgb(59 130 246);
}

.category-child {
	display: none;
}
.category-parent:hover .category-child {
	display: block;
}
